import React from 'react';
import { Router, Redirect, Link, Match } from '@reach/router';
import Providers from './providers';
import { light } from './configs/themes';
import Notifications from 'react-notify-toast';
import { init } from './utils/icons';
import SignIn_ from './pages/auth/SignIn';
import ResetPassword from './pages/auth/ResetPassword';
import NewPassword from './pages/auth/NewPassword';
import { withAuth } from './providers/AuthContext';
import SignUp_ from './pages/auth/SignUp';
import Apps from './pages/Apps';
import DevApp from './pages/App';
import AppBasic from './pages/App/Basic';
import AppOauth from './pages/App/Oauth';
import AppHooks from './pages/App/Webhooks';
import {
  TopAppBar,
  TopAppBarRow,
  TopAppBarSection,
  TopAppBarTitle,
  TopAppBarFixedAdjust,
  TopAppBarActionItem,
} from './components/material/TopAppBar';
import Button from './components/material/Button';
import { FormattedMessage, defineMessages } from 'react-intl';
import Logout_ from './components/Logout';

const SignIn = withAuth(SignIn_, true);
const SignUp = withAuth(SignUp_, true);
const Logout = withAuth(Logout_, true);

const DOCS_URL =
  'https://documenter.getpostman.com/view/58044/S1ZufXFZ?version=latest';

const messages = defineMessages({
  apps: { id: 'topbar_apps' },
  docs: { id: 'topbar_docs' },
  graphql: { id: 'topbar_graphql' },
});

init();

const makeTopBarLink = LinkComponent => ({ to, message }) => {
  return (
    <LinkComponent to={to} style={{ textDecoration: 'none', marginLeft: 5 }}>
      <Match path={`${to}/*`}>
        {props => (
          <Button unelevated className={props.match ? 'active-button' : ''}>
            <FormattedMessage {...message} />
          </Button>
        )}
      </Match>
    </LinkComponent>
  );
};
const ExternalLink = ({ to, children }) => (
  <a
    href={to}
    target="_blank"
    style={{ textDecoration: 'none' }}
    rel="noopener noreferrer"
  >
    {children}
  </a>
);
const InternalTopBarLink = makeTopBarLink(Link);
const ExternalTopBarLink = makeTopBarLink(ExternalLink);

const Layout = ({ children }) => (
  <>
    <TopAppBar>
      <TopAppBarRow>
        <TopAppBarSection>
          <TopAppBarTitle>Keenoa API</TopAppBarTitle>
        </TopAppBarSection>
        <TopAppBarSection alignEnd>
          <InternalTopBarLink to="/apps" message={messages.apps} />
          <ExternalTopBarLink to={DOCS_URL} message={messages.docs} />
          <ExternalTopBarLink to="/graphql" message={messages.graphql} />
          <TopAppBarActionItem icon={<Logout />} />
        </TopAppBarSection>
      </TopAppBarRow>
    </TopAppBar>
    <TopAppBarFixedAdjust />
    <div style={{ padding: '1.5rem' }}>{children}</div>
  </>
);

const App = () => (
  <div>
    <Providers>
      <Notifications
        colors={{
          error: { backgroundColor: light.error },
          success: { backgroundColor: light.success },
          warning: { backgroundColor: light.warning },
          info: { backgroundColor: light.primary1 },
        }}
      />
      <Layout>
        <Router primary={false}>
          <Apps path="apps" />
          <DevApp path="apps/:id">
            <AppBasic path="basic" />
            <AppOauth path="oauth" />
            <AppHooks path="webhooks" />
          </DevApp>
          <SignUp path="signup" />
          <SignIn path="signin" />
          <ResetPassword path="signin/reset" />
          <NewPassword path="signin/new" />
          <Redirect from="/" to="apps" />
        </Router>
      </Layout>
    </Providers>
  </div>
);

export default App;
