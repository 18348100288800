import React from 'react';
import ReactModal from 'react-modal';

function Modal({ isOpen, onClose, children, style }) {
  return (
    <ReactModal
      style={{
        content: {
          top: '30%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          maxWidth: '400px',
          maxHeight: '80vh',
          overflow: 'auto',
          marginRight: '-50%',
          width: '100%',
          height: 'auto',
          WebkitFontSmoothing: 'antialiased',
          transform: 'translate(-50%, -30%)',
          ...style,
        },
      }}
      isOpen={isOpen}
      onRequestClose={onClose}
    >
      {children}
    </ReactModal>
  );
}

export default Modal;
