import { connect } from 'react-redux';
import ScopeSection from './ScopeSection';
import { getAppScope, updateApp } from '../../../../redux/modules/apps';

const mapStateToProps = (state, { id }) => ({
  scope: getAppScope(state, id),
});

const mapDispatchToProps = dispatch => ({
  updateScope: (id, newScope) => dispatch(updateApp(id, { scope: newScope })),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ScopeSection);
