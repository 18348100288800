import '@material/dialog/dist/mdc.dialog.css';
import '@material/button/dist/mdc.button.css';
import {
  Dialog as D,
  DialogTitle as DT,
  DialogContent as DC,
  DialogButton as DB,
  DialogActions as DA,
  SimpleDialog as SD,
} from '@rmwc/dialog';

export const Dialog = D;
export const DialogTitle = DT;
export const DialogContent = DC;
export const DialogButton = DB;
export const DialogActions = DA;
export const SimpleDialog = SD;
