import styled from 'styled-components';

export const Section = styled.div`
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.17);
  background-color: white;
  padding: 30px 25px;
  display: flex;
  flex-direction: column;
  margin-top: 25px;
`;
