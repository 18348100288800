import React from 'react';
import AuthContextProvider from './AuthContext';
import { ThemeProvider as MThemeProvider } from '@rmwc/theme';
import '@material/theme/dist/mdc.theme.css';
import { Provider as StoreProvider } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import { IntlProvider } from 'react-intl';
import { getLs, putLs } from '../utils/storage';
import * as i18n from '../configs/locales';
import { light } from '../configs/themes';
import { getParam } from '../utils/window';
import { store } from '../redux';

export default ({ children }) => {
  const locale = _getLocale();
  return (
    <StoreProvider store={store}>
      <IntlProvider key={locale} locale={locale} messages={i18n[locale]}>
        <ThemeProvider theme={light}>
          <MThemeProvider
            options={{
              primary: light.primary1,
              secondary: light.primary1Saturated,
              background: '#f8f8f8',
            }}
          >
            <AuthContextProvider>{children}</AuthContextProvider>
          </MThemeProvider>
        </ThemeProvider>
      </IntlProvider>
    </StoreProvider>
  );
};

function _getLocale() {
  const l = getParam('locale');
  if ((l && l === 'fr') || l === 'en') {
    putLs('locale', l);
    return l;
  }
  let locale = getLs('locale');
  if (!locale) {
    locale = 'en';
    putLs('locale', locale);
  }
  return locale;
}
