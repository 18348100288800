import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Typography from '../../../components/material/Typography';
import { defineMessages, FormattedMessage } from 'react-intl';
import { TextField } from '../../../components/material/Texfield';
import { Section } from '../../../components/Section';
import CircularProgress from '../../../components/material/CircularProgress';
import Button from '../../../components/material/Button';
import { Spacer } from '../../../components/Spacer';
import './Basic.css';
import {
  Dialog,
  DialogContent,
  DialogActions,
  DialogButton,
} from '../../../components/material/Dialog';
import { DialogTitle } from '@rmwc/dialog';

const messages = defineMessages({
  title: { id: 'app_title' },
  credentialTitle: { id: 'app_credentialTitle' },
  credentialSubtitle: { id: 'app_credentialSubtitle' },
  clientId: { id: 'app_clientId' },
  clientSecret: { id: 'app_clientSecret' },
  clientText: { id: 'app_clientText' },
  displayTitle: { id: 'app_displayTitle' },
  displaySubtitle: { id: 'app_displaySubtitle' },
  name: { id: 'app_name' },
  description: { id: 'app_description' },

  website: { id: 'app_website' },
  privacy: { id: 'app_privacy' },
  terms: { id: 'app_privacy' },
});

const Wrapper = styled.form`
  display: flex;
  flex-direction: column;

  --mdc-theme-primary: ${x => x.theme.primary1};
`;

function ConfirmRegenerate({ open, setOpen, onAccept }) {
  return (
    <>
      <Dialog
        open={open}
        onClose={evt => {
          if (evt.detail.action === 'accept') onAccept();
          setOpen(false);
        }}
      >
        <DialogTitle>Regenerate Client Secret</DialogTitle>
        <DialogContent>
          This will not invalidate existing tokens. Are you sure?.
        </DialogContent>
        <DialogActions>
          <DialogButton outlined action="close">
            No, go back
          </DialogButton>
          <DialogButton unelevated danger action="accept" isDefaultAction>
            Yes, I'm sure
          </DialogButton>
        </DialogActions>
      </Dialog>
    </>
  );
}

function Basic({
  id,
  secret,
  name,
  description,
  website,
  privacy,
  terms,
  updateApp,
}) {
  const [eName, setName] = useState(name);
  const [eDescription, setDescription] = useState(description);
  const [eWebsite, setWebsite] = useState(website);
  const [ePrivacy, setPrivacy] = useState(privacy);
  const [eTerms, setTerms] = useState(terms);
  const [loading, setLoading] = useState(false);
  const [secretVisible, setSecretVisible] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);

  useEffect(() => {
    setName(name);
  }, [name]);
  useEffect(() => {
    setDescription(description);
  }, [description]);
  useEffect(() => {
    setWebsite(website);
  }, [website]);
  useEffect(() => {
    setPrivacy(privacy);
  }, [privacy]);
  useEffect(() => {
    setTerms(terms);
  }, [terms]);

  const saveChanges = async () => {
    const p = {};
    if (eName !== name) p.name = eName;
    if (eDescription !== description) p.description = eDescription;
    if (eWebsite !== website) p.website_url = eWebsite;
    if (eTerms !== terms) p.terms_url = eTerms;
    if (ePrivacy !== privacy) p.privacy_url = ePrivacy;

    setLoading(true);
    await updateApp(id, p);
    setLoading(false);
  };

  const regenerateSecret = async () => {
    await updateApp(id, { secret: true });
  };

  const renderCrendentials = () => (
    <Section>
      <Typography use="headline5" style={{ marginBottom: 15 }}>
        <FormattedMessage {...messages.credentialTitle} />
      </Typography>
      <Typography use="body2" style={{ marginBottom: 15 }} rank="primary">
        <FormattedMessage {...messages.credentialSubtitle} />
      </Typography>
      <Typography use="subtitle2">
        <FormattedMessage {...messages.clientId} />
      </Typography>
      <TextField outlined type="text" value={id} readOnly onChange={() => {}} />
      <Spacer />
      <Typography use="subtitle2">
        <FormattedMessage {...messages.clientSecret} />
      </Typography>
      <div style={{ position: 'relative', width: 600 }}>
        <TextField
          outlined
          className={!secretVisible ? 'textfield-secret' : ''}
          readOnly
          style={{ width: '100%' }}
          type={secretVisible ? 'text' : 'password'}
          value={secretVisible ? secret : '1234567890'}
          onChange={() => {}}
        />
        <Button
          label={'Regenerate'}
          outlined
          danger
          onClick={() => {
            setConfirmOpen(true);
          }}
          style={{
            position: 'absolute',
            top: 10,
            right: 10,
            zIndex: 1,
            background: 'white',
          }}
        />

        <Button
          label={secretVisible ? 'Hide' : 'Show'}
          outlined
          onClick={e => {
            e.preventDefault();
            setSecretVisible(!secretVisible);
          }}
          style={{
            position: 'absolute',
            top: 10,
            right: 130,
            zIndex: 1,
            background: 'white',
          }}
        />
      </div>

      <Typography use="body2" rank="secondary">
        <FormattedMessage {...messages.clientText} />
      </Typography>
    </Section>
  );

  const renderDisplayInfo = () => (
    <Section>
      <Wrapper
        onSubmit={e => {
          e.preventDefault();
          saveChanges();
        }}
      >
        <Typography use="headline5" style={{ marginBottom: 15 }}>
          <FormattedMessage {...messages.displayTitle} />
        </Typography>
        <Typography use="body2" style={{ marginBottom: 15 }} rank="primary">
          <FormattedMessage {...messages.displaySubtitle} />
        </Typography>
        <Typography use="subtitle2">
          <FormattedMessage {...messages.name} />
        </Typography>
        <TextField
          outlined
          type="text"
          value={eName}
          onChange={e => setName(e.target.value)}
        />
        <Spacer />
        <Typography use="subtitle2">
          <FormattedMessage {...messages.description} />
        </Typography>
        <TextField
          outlined
          textarea
          type="text"
          rows="2"
          value={eDescription}
          characterCount
          maxLength={300}
          onChange={e => setDescription(e.target.value)}
        />
        <Spacer />
        <Typography use="subtitle2">
          <FormattedMessage {...messages.website} />
        </Typography>
        <TextField
          outlined
          required
          type="url"
          value={eWebsite}
          onChange={e => setWebsite(e.target.value)}
        />
        <Spacer />
        <Typography use="subtitle2">
          <FormattedMessage {...messages.privacy} />
        </Typography>
        <TextField
          outlined
          required
          type="url"
          value={ePrivacy}
          onChange={e => setPrivacy(e.target.value)}
        />
        <Spacer />
        <Typography use="subtitle2">
          <FormattedMessage {...messages.terms} />
        </Typography>
        <TextField
          outlined
          required
          type="url"
          value={eTerms}
          onChange={e => setTerms(e.target.value)}
        />
        <Spacer />
        <Button
          label={loading ? 'Loading...' : 'Save changes'}
          unelevated
          disabled={
            (eName === name &&
              eDescription === description &&
              eWebsite === website &&
              eTerms === terms &&
              ePrivacy === privacy) ||
            loading
          }
          style={{ marginLeft: 'auto' }}
          icon={loading && <CircularProgress />}
        />
      </Wrapper>
    </Section>
  );

  return (
    <>
      <Typography use="headline4" style={{ marginBottom: 15 }}>
        <FormattedMessage {...messages.title} />
      </Typography>
      {renderCrendentials()}
      {renderDisplayInfo()}
      <ConfirmRegenerate
        open={confirmOpen}
        setOpen={setConfirmOpen}
        onAccept={regenerateSecret}
      />
    </>
  );
}

export default Basic;
