import has from 'lodash/has';
import get from 'lodash/get';
import last from 'lodash/last';
import merge from 'lodash/merge';
import FETCH_STATUS from '../constants/fetchStatus';

/**
 * Return a byId reducer for the entityName. This reducer work
 * with entities noramalized by normalizr.
 * @param {string} entityName
 * @param {function} mergeFn : function to merge the conflicting value
 */
export const makeById = (entityName, mergeFn) => (state = {}, action) => {
  const normalizedEntityLocation = `response.entities.${entityName}`;
  if (has(action, normalizedEntityLocation)) {
    const newObj = get(action, normalizedEntityLocation);
    return merge({}, state, newObj);
  }
  return state;
};

export const makeError = prefix => (state = null, action) => {
  if (!has(action, 'type') || !action.type.startsWith(prefix)) return state;

  switch (last(action.type.split('_'))) {
    case 'REQUEST':
    case 'SUCCESS':
      return null;
    case 'FAILURE':
      return action.error;
    default:
      return state;
  }
};

export const makeFetchStatus = prefix => (
  state = FETCH_STATUS.NONE,
  action
) => {
  if (!has(action, 'type') || !action.type.startsWith(prefix)) return state;

  switch (last(action.type.split('_'))) {
    case 'REQUEST':
      return FETCH_STATUS.LOADING;
    case 'SUCCESS':
      return FETCH_STATUS.LOADED;
    case 'FAILURE':
      return FETCH_STATUS.FAILED;
    default:
      return state;
  }
};

export const makeByIdFetchStatus = (prefix, idLocation) => {
  const fetchStatus = makeFetchStatus(prefix);

  return (state = {}, action) => {
    if (!has(action, 'type') || !action.type.startsWith(prefix)) return state;

    const id = get(action, idLocation);
    return {
      ...state,
      [id]: fetchStatus(state[id], action),
    };
  };
};
