import React from 'react';
import Typography from '../../../../components/material/Typography';
import Button from '../../../../components/material/Button';
import { Spacer } from '../../../../components/Spacer';
import uuid from 'uuid/v4';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconButton } from '../../../../components/material/IconButton';
import { Select } from '../../../../components/material/Select';

function Events({ events, setEvents }) {
  const _addEvent = e => {
    e.preventDefault();

    const newE = { id: uuid(), type: '', entity: '' };
    setEvents([...events, newE]);
  };

  const _updateType = (id, type) => {
    setEvents(
      events.map(e => {
        if (e.id !== id) return e;
        return { ...e, type };
      })
    );
  };

  const _updateEntity = (id, entity) => {
    setEvents(
      events.map(e => {
        if (e.id !== id) return e;
        return { ...e, entity };
      })
    );
  };

  const _deleteEvent = id => {
    setEvents(events.filter(e => e.id !== id));
  };

  const renderEvent = ({ id, type, entity }) => {
    return (
      <div
        key={id}
        style={{
          display: 'flex',
          flexDirection: 'row',
          marginBottom: 10,
        }}
      >
        <div style={{ marginRight: 5 }}>
          <Select
            label="Entity"
            outlined
            required
            value={entity}
            onChange={e => _updateEntity(id, e.target.value)}
            options={['INVITE']}
          />
        </div>
        <Select
          label="Action"
          value={type}
          outlined
          required
          onChange={e => _updateType(id, e.target.value)}
          options={['CREATE', 'UPDATE', 'DELETE']}
        />
        <div>
          <IconButton
            style={{ fontSize: 18, color: 'rgba(0,0,0,0.72)' }}
            onClick={e => {
              e.preventDefault();
              _deleteEvent(id);
            }}
            icon={<FontAwesomeIcon icon={['far', 'trash-alt']} />}
          />
        </div>
      </div>
    );
  };

  return (
    <>
      <Typography use="subtitle2">Events</Typography>
      <div>
        <Spacer />
        {events.map(renderEvent)}
        <Spacer />
        <Button label={'+ Add event'} onClick={_addEvent} />
      </div>
    </>
  );
}

export default Events;
