import React, { useEffect, useState } from 'react';
import { Link } from '@reach/router';
import styled from 'styled-components';
import Typography from '../../../components/material/Typography';
import { defineMessages, FormattedMessage } from 'react-intl';
import Button from '../../../components/material/Button';
import NewHook from './NewHook';
import EditHook from './NewHook/index.edit';
import { Section } from '../../../components/Section';
import { TextField } from '../../../components/material/Texfield';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import lowerCase from 'lodash/lowerCase';

const messages = defineMessages({
  title: { id: 'hooks_title' },
  empty: { id: 'hooks_empty' },
});

const PageWrapper = styled.div`
  display: flex;
  align-items: stretch;
  flex-direction: column;
  width: 650px;

  margin: 0 auto;
`;

const TableHeader = styled.div`
  border-bottom: 2px solid rgba(0, 0, 0, 0.1);
  padding: 5px;
  display: flex;
  flex-direction: row;
`;

const AppRow = styled.div`
  padding: 6px 5px;
  margin: 2px 0;
  display: block;
  text-decoration: none;
  color: ${x => x.theme.text.primary};
  display: flex;
  flex-direction: row;
  border-radius: 6px;

  &:hover {
    cursor: pointer;
    background-color: #4990e226;
  }
`;

const Nohooks = styled(Typography)`
  color: ${x => x.theme.text.secondary};
  margin: 30px auto;
`;

function Webhooks({ appId, fetchHooks, hooks }) {
  const [isLoading, setIsLoading] = useState(true);
  const [newHookOpen, setNewHookOpen] = useState(false);
  const [filter, setFilter] = useState('');
  const [selectedHook, setSelectedHook] = useState(null);

  const _fetchhooks = async () => {
    setIsLoading(true);
    await fetchHooks(appId);
    setIsLoading(false);
  };
  useEffect(() => {
    _fetchhooks();
  }, []);

  const renderHeader = () => (
    <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
      <Typography use="headline4">
        <FormattedMessage {...messages.title} />
      </Typography>
      <Button
        label={'New webhook'}
        unelevated
        onClick={() => setNewHookOpen(!newHookOpen)}
        style={{ marginLeft: 'auto', padding: '0 50px' }}
      />
    </div>
  );

  const renderSearchBar = () => (
    <TextField
      style={{ marginTop: 15, marginBottom: -15 }}
      icon={<FontAwesomeIcon icon={['far', 'search']} value={filter} />}
      onChange={e => setFilter(e.target.value)}
      outlined
      placeholder={'Filter webhooks by url, event type or event entity'}
    />
  );

  const renderTableHeader = () => (
    <TableHeader>
      <div style={{ width: '40%' }}>
        <Typography use="subtitle2" style={{ width: '40%' }}>
          Request URL
        </Typography>
      </div>
      <Typography use="subtitle2">Events</Typography>
    </TableHeader>
  );

  const regex = new RegExp(filter, 'i');
  const visibleHooks = hooks.filter(
    h =>
      (h.url && regex.test(h.url)) ||
      h.events.filter(e => regex.test(`${e.type}:${e.entity}`)).length > 0
  );

  const formatEvents = events => {
    return events
      .map(e => `${lowerCase(e.entity)}:${lowerCase(e.type)}`)
      .join(', ');
  };

  const renderhooks = () => (
    <>
      {renderTableHeader()}
      {visibleHooks.map(hook => (
        <AppRow key={hook.id} onClick={() => setSelectedHook(hook.id)}>
          <div
            style={{
              width: '40%',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              paddingRight: 10,
              boxSizing: 'border-box',
            }}
          >
            <Typography use="body2">{hook.url}</Typography>
          </div>
          <div
            style={{
              width: '60%',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            <Typography use="body2" style={{ whiteSpace: 'nowrap' }}>
              {formatEvents(hook.events)}
            </Typography>
          </div>
        </AppRow>
      ))}
    </>
  );

  const renderNohooks = () => (
    <Nohooks>
      <FormattedMessage {...messages.empty} />
    </Nohooks>
  );

  return (
    <PageWrapper>
      {renderHeader()}
      {renderSearchBar()}

      {isLoading ? null : (
        <Section>
          {!visibleHooks.length && !isLoading ? renderNohooks() : renderhooks()}
        </Section>
      )}
      <NewHook
        appId={appId}
        isOpen={newHookOpen}
        onClose={() => setNewHookOpen(false)}
      />
      <EditHook
        appId={appId}
        hookId={selectedHook}
        isOpen={!!selectedHook}
        onClose={() => setSelectedHook(null)}
      />
    </PageWrapper>
  );
}

export default Webhooks;
