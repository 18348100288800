import { connect } from 'react-redux';
import Oauth from './Oauth';

const mapStateToProps = (state, { id }) => ({
  id,
});

const mapDispatchToProps = dispatch => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Oauth);
