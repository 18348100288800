import React from 'react';
import Typography from '../../../components/material/Typography';
import { defineMessages, FormattedMessage } from 'react-intl';
import ScopeSection from './ScopeSection';
import RedirectSection from './RedirectSection';

const messages = defineMessages({
  title: { id: 'app_sidebar_oauth' },
});

function Oauth({ id }) {
  return (
    <>
      <Typography use="headline4" style={{ marginBottom: 15 }}>
        <FormattedMessage {...messages.title} />
      </Typography>
      <RedirectSection id={id} />
      <ScopeSection id={id} />
      <div style={{ height: 250 }} />
    </>
  );
}

export default Oauth;
