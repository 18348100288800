import { connect } from 'react-redux';
import { fetchHooks } from '../../../redux/modules/hooks';
import Webhooks from './Webhooks';
import { getAppHooks } from '../../../redux/modules/apps';

const mapStateToProps = (state, { id }) => ({
  hooks: getAppHooks(state, id),
  appId: id,
});

const mapDispatchToProps = dispatch => ({
  fetchHooks: id => dispatch(fetchHooks(id)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Webhooks);
