import { flattenTranslationObject } from './utils';
import { addLocaleData } from 'react-intl';
import en from 'react-intl/locale-data/en';

addLocaleData(en);

/** WARNING : The keys that are used cannot be refactored directly
 * if you want to change the name of an object, perform a full text search
 * with the full path camera.title.x to find all usage that you will have
 * to be updated manually */
const translations = {
  logout: 'Signout',
  signup: {
    title: 'Developer account',
    name: 'Full name',
    company: 'Company',
    already: 'Already have an account ? Sign in',
    submit: 'Sign up',
  },
  login: {
    title: 'Log in',
    forgotBackToLogin: 'Back to login',
    help:
      'Please use the same email and password than in the Keenoa mobile app.',
    welcome: 'Hello!',
    forgotPassword: 'Forgot Password?',
    forgotTitle: 'Account recovery',
    forgotSubmit: 'Next',
    forgotInstruction:
      'Enter your email adress. If a Keenoa account is linked to it, you will receive an email.',
    forgotConfirmation:
      'Great! Now, check your email at the address: {email}. You should receive a link to reset your password within 3 minutes. If you dont, it most likely means that your Keenoa account is linked to a different account.',
    forgotAssistance: 'Need further assistance ? ',
    forgotContact: 'Contact our support team (support@keenoa.co)',
    forgotBack: 'Try another email',
    forgotHelpTitle: 'I need help recovering my account',
    forgotConfirmationTitle: 'Check your email',
    forgotHelpBody:
      'Hey team Keenoa 👋! \n\nI have some trouble accessing my Keenoa account, I tried reseting my password with the email: {email}, but it did not work.😥 \n\nCan you help me ?\n\n Thanks',
    submit: 'Log In',
    noAccount: "Don't have an account?",
    signUp: 'Create an account',
    email: 'Email',
    password: 'Password',
    passwordHelp: 'Min 6 characters',
    error:
      'Sorry, we could not find your account with this email and password.',
    new: {
      title: 'New password',
      instruction: 'Please choose a new pasword below.',
      submit: 'Set password',

      successTitle: 'Success',
      successText:
        'You can now go back to your Keenoa mobile app and use your new password to access you Keenoa account. If you wanted to access your reports, you can use the button below to login into the web portal.',
      successSubmit: 'Web connection',

      noToken: 'Please use the link that you received by email.',
      badToken: 'Your link has expired, please make a new request.',
    },
  },
  apps: {
    title: 'Your Apps',
    empty: 'Please create an app to get access to the Keenoa api.',
  },
  hooks: {
    title: 'Webhooks',
    empty: 'No Webhooks yet.',
    urlHelp:
      'We’ll send HTTP POST requests to this URL when events configured below occur. ',
    headersHelp:
      'As an additional security measure for webhooks POSTs, you may wish to include custom headers so that queries can be securely sent to your request URL.',
  },
  app: {
    title: 'Basic Information',
    credentialTitle: 'App Credentials',
    credentialSubtitle:
      "These credentials allow your app to access the Keenoa API. They are secret. Please don't share your app credentials with anyone, include them in public code repositories, or store them in insecure ways.",
    clientId: 'Client ID',
    clientSecret: 'Client Secret',
    clientText:
      "You'll need to send this secret along with your client ID when making your oauth.access request.",
    displayTitle: 'Display Information',
    displaySubtitle:
      'This information will be displayed to the user when requesting for their consent to access their data.',
    name: 'App name',
    website: 'Website URL',
    terms: 'Terms URL',
    privacy: 'Privacy policy URL',
    description: 'Short description',
    sidebar: {
      basic: 'Basic',
      oauth: 'OAuth & Permissions',
      hooks: 'Webhooks',
    },
    oauth: {
      redirect: 'Redirect URLs',
      redirectSubtitle:
        'You will need to configure redirect URLs in order to request oauth access. When you pass a URL in an OAuth request, it must match one of the URLs you enter here.',
      scopes: 'Scopes',
      scopesSubtitle:
        'Scopes define the API methods this app is allowed to call, and thus which infomation and capabilities are available on a dietitian account. If your app is submitted Keenoa, we’ll review your reasons for requesting each scope. After your app is listed, it will only be able to use permission scopes Keenoa has approved.',
    },
  },
  topbar: {
    apps: 'Your Apps',
    docs: 'API documentation',
    graphql: 'API explorer',
  },
  report: {
    download: 'Download',
  },
};

export default flattenTranslationObject(translations);
