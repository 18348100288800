import {
  TextField as TF,
  TextFieldIcon as TFI,
  TextFieldHelperText as TFHT,
} from '@rmwc/textfield';

import '@material/textfield/dist/mdc.textfield.css';
import '@material/floating-label/dist/mdc.floating-label.css';
import '@material/notched-outline/dist/mdc.notched-outline.css';
import '@material/line-ripple/dist/mdc.line-ripple.css';
import './Textfield.css';

export const TextField = TF;
export const TextFieldIcon = TFI;
export const TextFieldHelperText = TFHT;
