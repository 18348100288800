import { combineReducers } from 'redux';
import apps from './apps';
import hooks from './hooks';

const appReducer = combineReducers({
  apps,
  hooks,
});

export default appReducer;
