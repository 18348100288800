const pre = 'keenoa/hooks/';

export default {
  CREATE_HOOK_REQUEST: `${pre}CREATE_HOOK_REQUEST`,
  CREATE_HOOK_SUCCESS: `${pre}CREATE_HOOK_SUCCESS`,
  CREATE_HOOK_FAILURE: `${pre}CREATE_HOOK_FAILURE`,

  FETCH_HOOKS_REQUEST: `${pre}FETCH_HOOKS_REQUEST`,
  FETCH_HOOKS_SUCCESS: `${pre}FETCH_HOOKS_SUCCESS`,
  FETCH_HOOKS_FAILURE: `${pre}FETCH_HOOKS_FAILURE`,

  FETCH_HOOK_REQUEST: `${pre}FETCH_HOOK_REQUEST`,
  FETCH_HOOK_SUCCESS: `${pre}FETCH_HOOK_SUCCESS`,
  FETCH_HOOK_FAILURE: `${pre}FETCH_HOOK_FAILURE`,

  DELETE_HOOK_REQUEST: `${pre}DELETE_HOOK_REQUEST`,
  DELETE_HOOK_SUCCESS: `${pre}DELETE_HOOK_SUCCESS`,
  DELETE_HOOK_FAILURE: `${pre}DELETE_HOOK_FAILURE`,

  UPDATE_HOOK_REQUEST: `${pre}UPDATE_HOOK_REQUEST`,
  UPDATE_HOOK_SUCCESS: `${pre}UPDATE_HOOK_SUCCESS`,
  UPDATE_HOOK_FAILURE: `${pre}UPDATE_HOOK_FAILURE`,
};
