import React from 'react';
import { removeLs } from '../utils/storage';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, navigate } from '@reach/router';

function Logout({ token }) {
  const logout = () => {
    removeLs('token');
    navigate('/signin');
  };

  return token ? (
    <FontAwesomeIcon icon={['far', 'sign-out']} onClick={() => logout()} />
  ) : (
    <Link to="/signin" style={{ textDecoration: 'none', color: 'white' }}>
      <FontAwesomeIcon icon={['far', 'sign-in']} />
    </Link>
  );
}

export default Logout;
