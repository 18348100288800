import { connect } from 'react-redux';
import RedirectSection from './RedirectSection';
import { updateApp, getAppRedirectUrls } from '../../../../redux/modules/apps';

const mapStateToProps = (state, { id }) => ({
  redirects: getAppRedirectUrls(state, id),
});

const mapDispatchToProps = dispatch => ({
  updateRedirects: (id, redirects) =>
    dispatch(updateApp(id, { callback_urls: redirects })),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RedirectSection);
