import React, { useEffect, useState } from 'react';
import { withAuth } from '../../providers/AuthContext';
import { Link } from '@reach/router';
import styled from 'styled-components';
import Typography from '../../components/material/Typography';
import { defineMessages, FormattedMessage } from 'react-intl';
import Button from '../../components/material/Button';
import NewApp from '../NewApp';
import { Section } from '../../components/Section';
import { TextField } from '../../components/material/Texfield';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const messages = defineMessages({
  title: { id: 'apps_title' },
  empty: { id: 'apps_empty' },
});

const PageWrapper = styled.div`
  display: flex;
  align-items: stretch;
  flex-direction: column;
  width: 650px;

  margin: 0 auto;
`;

const TableHeader = styled.div`
  border-bottom: 2px solid rgba(0, 0, 0, 0.1);
  padding: 5px;
  display: flex;
  flex-direction: row;
`;

const AppRow = styled(Link)`
  padding: 6px 5px;
  margin: 2px 0;
  display: block;
  text-decoration: none;
  color: ${x => x.theme.text.primary};
  display: flex;
  flex-direction: row;
  border-radius: 6px;

  &:hover {
    cursor: pointer;
    background-color: #4990e226;
  }
`;

const NoApps = styled(Typography)`
  color: ${x => x.theme.text.secondary};
  margin: 30px auto;
`;

function Apps({ fetchApps, apps }) {
  const [isLoading, setIsLoading] = useState(true);
  const [newAppOpen, setNewAppOpen] = useState(false);
  const [filter, setFilter] = useState('');

  const _fetchApps = async () => {
    setIsLoading(true);
    await fetchApps();
    setIsLoading(false);
  };
  useEffect(() => {
    _fetchApps();
  }, []);

  const renderHeader = () => (
    <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
      <Typography use="headline4">
        <FormattedMessage {...messages.title} />
      </Typography>
      <Button
        label={'New app'}
        unelevated
        onClick={() => setNewAppOpen(!newAppOpen)}
        style={{ marginLeft: 'auto', padding: '0 50px' }}
      />
    </div>
  );

  const renderSearchBar = () => (
    <TextField
      style={{ marginTop: 15, marginBottom: -15 }}
      icon={<FontAwesomeIcon icon={['far', 'search']} value={filter} />}
      onChange={e => setFilter(e.target.value)}
      outlined
      placeholder={'Filter apps by name or description'}
    />
  );

  const renderTableHeader = () => (
    <TableHeader>
      <div style={{ width: '30%' }}>
        <Typography use="subtitle2" style={{ width: '30%' }}>
          App Name
        </Typography>
      </div>
      <Typography use="subtitle2">Short Description</Typography>
    </TableHeader>
  );

  const regex = new RegExp(filter, 'i');
  const visibleApps = apps.filter(
    a =>
      (a.name && regex.test(a.name)) ||
      (a.description && regex.test(a.description))
  );

  const renderApps = () => (
    <>
      {renderTableHeader()}
      {visibleApps.map(app => (
        <AppRow key={app.id} to={`${app.id}/basic`}>
          <div style={{ width: '30%' }}>
            <Typography use="body2" style={{ width: '30%' }}>
              {app.name}
            </Typography>
          </div>
          <div
            style={{
              width: '70%',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            <Typography use="body2" style={{ whiteSpace: 'nowrap' }}>
              {app.description}
            </Typography>
          </div>
        </AppRow>
      ))}
    </>
  );

  const renderNoApps = () => (
    <NoApps>
      <FormattedMessage {...messages.empty} />
    </NoApps>
  );

  return (
    <PageWrapper>
      {renderHeader()}
      {renderSearchBar()}

      {isLoading ? null : (
        <Section>
          {!visibleApps.length && !isLoading ? renderNoApps() : renderApps()}
        </Section>
      )}
      <NewApp isOpen={newAppOpen} onClose={() => setNewAppOpen(false)} />
    </PageWrapper>
  );
}

export default withAuth(Apps);
