import { connect } from 'react-redux';
import Basic from './Basic';
import {
  getAppSecret,
  getAppName,
  getAppDescription,
  getAppWebsite,
  getAppPrivacy,
  getAppTerms,
  updateApp,
} from '../../../redux/modules/apps';

const mapStateToProps = (state, { id }) => ({
  id,
  secret: getAppSecret(state, id),
  name: getAppName(state, id),
  description: getAppDescription(state, id),
  website: getAppWebsite(state, id),
  privacy: getAppPrivacy(state, id),
  terms: getAppTerms(state, id),
});

const mapDispatchToProps = dispatch => ({
  updateApp: (id, props) => dispatch(updateApp(id, props)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Basic);
