import React, { useState, useEffect, useRef } from 'react';
import Typography from '../../../../components/material/Typography';
import { defineMessages, FormattedMessage } from 'react-intl';
import { Section } from '../../../../components/Section';
import { TextField } from '../../../../components/material/Texfield';
import styled from 'styled-components';
import { Spacer } from '../../../../components/Spacer';
import { IconButton } from '../../../../components/material/IconButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from '../../../../components/material/Button';
import isURL from 'validator/lib/isURL';
import CircularProgress from '../../../../components/material/CircularProgress';

const messages = defineMessages({
  redirect: { id: 'app_oauth_redirect' },
  redirectSubtitle: { id: 'app_oauth_redirectSubtitle' },
});

const RedirectsWrapper = styled.div`
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
`;
const Redirect = styled.div`
  padding: 0 10px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 55px;
`;

const Wrapper = styled.form`
  display: flex;
  flex-direction: column;
`;

function RedirectSection({ id, redirects, updateRedirects }) {
  const [eRedirects, setRedirects] = useState(redirects);
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState('');
  const inputEl = useRef(null);

  useEffect(() => {
    setRedirects(redirects);
  }, [redirects]);

  const deleteRedirect = redirect => {
    const next = eRedirects.filter(x => x !== redirect);
    setRedirects(next);
  };

  const saveRedirects = async () => {
    setLoading(true);
    await updateRedirects(id, eRedirects);
    setLoading(false);
  };

  const addRedirect = e => {
    e.preventDefault();
    setRedirects([...eRedirects, value]);
    setValue('');
  };

  const addReady = () => {
    if (!value) return false;

    return isURL(value);
  };

  const hasChanges = () => {
    const a = redirects.sort().join(',');
    const b = eRedirects.sort().join(',');
    return a !== b;
  };

  return (
    <Section>
      <Wrapper
        onSubmit={e => {
          e.preventDefault();
          saveRedirects();
        }}
      >
        <Typography use="headline5" style={{ marginBottom: 15 }}>
          <FormattedMessage {...messages.redirect} />
        </Typography>
        <Typography use="body2" style={{ marginBottom: 15 }} rank="primary">
          <FormattedMessage {...messages.redirectSubtitle} />
        </Typography>
        <Typography use="body2" style={{ marginBottom: 15 }} rank="primary">
          <strong>Tip:</strong> If you would like to test oauth on your local
          server, use{' '}
          <a
            href="https://ngrok.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            ngrok
          </a>{' '}
          to expose your local server on a valid URL.
        </Typography>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <TextField
            style={{ flex: 1 }}
            outlined
            value={value}
            onChange={e => setValue(e.target.value)}
            type="url"
            inputRef={x => (inputEl.current = x)}
            placeholder="Add a new redirect URL"
          />
          <Button
            style={{ marginLeft: 10 }}
            label={'Add URL'}
            unelevated
            onClick={addRedirect}
            disabled={!addReady()}
          />
        </div>
        <Spacer />
        <RedirectsWrapper>
          {!eRedirects.length ? (
            <div
              style={{ display: 'flex', justifyContent: 'center', padding: 10 }}
            >
              <Typography use="body2" rank="secondary">
                You haven’t added any redirect URL
              </Typography>
            </div>
          ) : null}
          {eRedirects.map(redirect => (
            <Redirect key={redirect}>
              <Typography use="body1">{redirect}</Typography>
              <div>
                <IconButton
                  style={{ fontSize: 18, color: 'rgba(0,0,0,0.72)' }}
                  onClick={e => {
                    e.preventDefault();
                    deleteRedirect(redirect);
                  }}
                  icon={<FontAwesomeIcon icon={['far', 'trash-alt']} />}
                />
              </div>
            </Redirect>
          ))}
        </RedirectsWrapper>
        <Spacer />
        <Button
          label={loading ? 'Loading...' : 'Save redirects'}
          unelevated
          disabled={!hasChanges() || loading}
          style={{ marginLeft: 'auto' }}
          icon={loading && <CircularProgress />}
        />
      </Wrapper>
    </Section>
  );
}

export default RedirectSection;
