import '@material/top-app-bar/dist/mdc.top-app-bar.css';
import {
  TopAppBar as TB,
  TopAppBarRow as TBR,
  TopAppBarSection as TBS,
  TopAppBarTitle as TBT,
  TopAppBarFixedAdjust as TBFA,
  TopAppBarActionItem as TBA,
} from '@rmwc/top-app-bar';

export const TopAppBar = TB;
export const TopAppBarRow = TBR;
export const TopAppBarSection = TBS;
export const TopAppBarTitle = TBT;
export const TopAppBarFixedAdjust = TBFA;
export const TopAppBarActionItem = TBA;
