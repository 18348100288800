import { createStore as _createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import combinedReducers from './modules';
import middlewares from './middlewares';

const enhancers = composeWithDevTools(applyMiddleware(...middlewares));
const createStore = initialState =>
  _createStore(combinedReducers, initialState, enhancers);

export default createStore;

export const store = createStore({});
