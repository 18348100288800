import { connect } from 'react-redux';
import NewHook from './NewHook';
import { createHook } from '../../../../redux/modules/hooks';

const mapDispatchToProps = dispatch => ({
  createHook: (id, payload) => dispatch(createHook(id, payload)),
});

export default connect(
  null,
  mapDispatchToProps
)(NewHook);
