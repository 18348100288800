import { connect } from 'react-redux';
import NewHook from './NewHook';
import {
  deleteHook,
  getHookUrl,
  getHookHeaders,
  getHookEvents,
  updateHook,
} from '../../../../redux/modules/hooks';

const mapStateToProps = (state, { hookId }) => ({
  startUrl: getHookUrl(state, hookId),
  startHeaders: getHookHeaders(state, hookId),
  startEvents: getHookEvents(state, hookId),
});

const mapDispatchToProps = dispatch => ({
  updateHook: (id, payload) => dispatch(updateHook(id, payload)),
  deleteHook: (appId, id) => dispatch(deleteHook(appId, id)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NewHook);
