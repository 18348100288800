import React, { useState } from 'react';
import Autosuggest from 'react-autosuggest';
import { TextField } from '../../../../components/material/Texfield';
import IsolatedScroll from 'react-isolated-scroll';
import Typography from '../../../../components/material/Typography';
import './ScopeInput.css';
import styled from 'styled-components';
import { scopes, JOIN_TOKEN } from './scopes';

const Suggestion = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: ${x => (x.isHighlighted ? '#4990e226' : 'white')};
  padding: 6px;
  margin-top: 2px
  margin-bottom: 2px;
  border-radius: 4px;

  &:hover {
    cursor: pointer;
  }
`;

function getSuggestionValue(suggestion) {
  return suggestion.text;
}

function renderSuggestion({ text, id }, { query, isHighlighted }) {
  return (
    <Suggestion isHighlighted={isHighlighted}>
      <div>
        <Typography use="body2" rank="primary">
          {text}
        </Typography>
      </div>
      <div>
        <Typography use="body2" rank="secondary">
          {id}
        </Typography>
      </div>
    </Suggestion>
  );
}

function renderSectionTitle(section) {
  return (
    <Typography use="body2" style={{ padding: 6 }}>
      <strong>{section.title}</strong>
    </Typography>
  );
}

function getSectionSuggestions(section) {
  return section.suggestions;
}

function ScopeInput({ addScope, currentScope }) {
  const [value, setValue] = useState('');
  const [focus, setFocus] = useState(false);
  const [suggestions, setSuggestions] = useState(getSuggestions(''));

  function getSuggestions(value) {
    const scopeMap = {};
    currentScope.split(JOIN_TOKEN).forEach(i => {
      scopeMap[i] = true;
    });

    const regex = new RegExp(value, 'i');
    return scopes
      .map(section => {
        return {
          title: section.title,
          suggestions: section.suggestions
            .filter(scope => !scopeMap[scope.id])
            .filter(
              scope =>
                value === '' || regex.test(scope.id) || regex.test(scope.text)
            ),
        };
      })
      .filter(section => section.suggestions.length > 0);
  }

  const onChange = (_, { newValue }) => {
    setValue(newValue);
  };

  const onSuggestionsFetchRequested = ({ value }) => {
    setSuggestions(getSuggestions(value));
  };

  const onSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  const renderInputComponent = props => (
    <TextField style={{ width: 650 }} outlined {...props} />
  );

  const renderNoItems = query => (
    <div style={{ padding: 5, display: 'flex', justifyContent: 'center' }}>
      <Typography use="body2">
        No items match <strong>{query}</strong>
      </Typography>
    </div>
  );

  const renderSuggestionsContainer = ({ containerProps, children, query }) => {
    const { ref, ...restContainerProps } = containerProps;
    const callRef = isolatedScroll => {
      if (isolatedScroll !== null) {
        ref(isolatedScroll.component);
      }
    };
    if (!focus) return null;

    return (
      <div style={{ position: 'relative', zIndex: 10 }} {...restContainerProps}>
        <IsolatedScroll
          ref={callRef}
          style={{
            maxHeight: 200,
            overflow: 'auto',
            border: '1px solid rgba(0,0,0,0.1)',
            padding: 10,
            top: 5,
            borderRadius: 6,
            position: 'absolute',
            left: 0,
            right: 0,
            backgroundColor: 'white',
            boxShadow: '0 0 3px rgba(0,0,0,.1)',
          }}
        >
          {children}
          {!suggestions.length ? renderNoItems(query) : null}
        </IsolatedScroll>
      </div>
    );
  };

  const onSuggestionSelected = (e, { suggestion }) => {
    addScope(suggestion.id);
    setValue('');
    e.target.blur();
  };

  return (
    <Autosuggest
      suggestions={suggestions}
      shouldRenderSuggestions={() => true}
      onSuggestionsFetchRequested={onSuggestionsFetchRequested}
      onSuggestionsClearRequested={onSuggestionsClearRequested}
      getSuggestionValue={getSuggestionValue}
      renderSectionTitle={renderSectionTitle}
      getSectionSuggestions={getSectionSuggestions}
      renderSuggestion={renderSuggestion}
      renderInputComponent={renderInputComponent}
      renderSuggestionsContainer={renderSuggestionsContainer}
      onSuggestionSelected={onSuggestionSelected}
      focusInputOnSuggestionClick={false}
      inputProps={{
        value,
        onChange,
        onFocus: () => setFocus(true),
        onBlur: () => setFocus(false),
        type: 'search',
        placeholder: 'Add permission by scope or API method...',
      }}
      multiSection
    />
  );
}

export default ScopeInput;
