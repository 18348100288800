import { schema } from 'normalizr';

export const hook = new schema.Entity('webhooks');
export const arrayOfHook = [hook];

export const app = new schema.Entity('apps', {
  webhooks: arrayOfHook,
});
export const arrayOfApp = [app];

export default {
  app,
  arrayOfApp,

  hook,
  arrayOfHook,
};
