import { connect } from 'react-redux';
import Apps from './Apps';
import { fetchApps, getApps } from '../../redux/modules/apps';

const mapStateToProps = state => ({
  apps: getApps(state),
});

const mapDispatchToProps = dispatch => ({
  fetchApps: () => dispatch(fetchApps()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Apps);
