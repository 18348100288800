export const JOIN_TOKEN = ',';

export const scopeToText = {
  'client:read': 'Access dietitian clients profile information (email & name).',
  'diary:read': 'Access dietitian’ clients nutritional information.',
  'diary:write': 'Modify dietitian’ clients food diaries',
  'comment:read':
    'Access comments exchanged between a dietitian ans its clients.',
  'comment:write': 'Add comments on behalf of a dietitian.',
  'photo:read': 'Access dietitian’ clients meal photos.',
  'invite:write': 'Send new client invitation on behalf of a dietitian.',
  'invite:read': 'Be notified and access the state of a dietitian invitations.',
};
export const scopes = [
  {
    title: 'Client',
    suggestions: [
      {
        id: 'client:read',
        text: scopeToText['client:read'],
      },
    ],
  },
  {
    title: 'Diary',
    suggestions: [
      {
        id: 'diary:read',
        text: scopeToText['diary:read'],
      },
      {
        id: 'diary:write',
        text: scopeToText['diary:write'],
      },
      {
        id: 'comment:read',
        text: scopeToText['comment:read'],
      },
      {
        id: 'comment:write',
        text: scopeToText['comment:write'],
      },
      {
        id: 'photo:read',
        text: scopeToText['photo:read'],
      },
    ],
  },
  {
    title: 'Invite',
    suggestions: [
      {
        id: 'invite:write',
        text: scopeToText['invite:write'],
      },
      {
        id: 'invite:read',
        text: scopeToText['invite:read'],
      },
    ],
  },
];
