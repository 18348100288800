import { combineReducers } from 'redux';
import { makeById } from '../../../utils/standardReducers';
import types from './apps.types';
import { secureCall, JSON_HEADER, json } from '../../../utils/api';
import schemas from '../schemas';
import { normalize } from 'normalizr';
import { createSelector } from 'reselect';
import get from 'lodash/get';
import hooksTypes from '../hooks/hooks.types';
import { getById as getHooksById } from '../hooks';

const _baseById = makeById('apps');
export const byId = (state = {}, action) => {
  let nextState = _baseById(state, action);
  if (action.type === hooksTypes.FETCH_HOOKS_SUCCESS) {
    return {
      ...nextState,
      [action.appId]: {
        ...get(state, action.appId),
        webhooks: action.response.result,
      },
    };
  }
  if (action.type === hooksTypes.CREATE_HOOK_SUCCESS) {
    return {
      ...nextState,
      [action.appId]: {
        ...get(state, action.appId),
        webhooks: [
          ...get(state, [action.appId, 'webhooks'], []),
          action.response.result,
        ],
      },
    };
  }
  if (action.type === hooksTypes.DELETE_HOOK_SUCCESS) {
    return {
      ...nextState,
      [action.appId]: {
        ...get(state, action.appId),
        webhooks: get(state, [action.appId, 'webhooks'], []).filter(
          h => h !== action.id
        ),
      },
    };
  }
  return nextState;
};

export default combineReducers({
  byId,
});

/// SELECTORS
export const _getById = state => state.apps.byId;
export const getApps = createSelector(
  _getById,
  byId => Object.values(byId)
);
/** (state, appId) */
export const getApp = createSelector(
  [_getById, (_, id) => id],
  (byId, id) => get(byId, id, null)
);
/** (state, appId) */
export const getAppId = createSelector(
  getApp,
  app => get(app, 'id', null)
);
export const getAppSecret = createSelector(
  getApp,
  app => get(app, 'secret', null)
);
export const getAppName = createSelector(
  getApp,
  app => get(app, 'name', null)
);
export const getAppDescription = createSelector(
  getApp,
  app => get(app, 'description', null)
);
export const getAppWebsite = createSelector(
  getApp,
  app => get(app, 'website_url', null)
);
export const getAppPrivacy = createSelector(
  getApp,
  app => get(app, 'privacy_url', null)
);
export const getAppTerms = createSelector(
  getApp,
  app => get(app, 'terms_url', null)
);
export const getAppScope = createSelector(
  getApp,
  app => get(app, 'scope', '')
);
export const getAppRedirectUrls = createSelector(
  getApp,
  app => get(app, 'callback_urls', [])
);
const _getAppHooks = createSelector(
  getApp,
  app => get(app, 'webhooks', [])
);
export const getAppHooks = createSelector(
  [_getAppHooks, getHooksById],
  (hooks, byId) => hooks.map(id => byId[id])
);

// ACTIONS
export const fetchApps = () => ({
  types: [
    types.FETCH_APPS_REQUEST,
    types.FETCH_APPS_SUCCESS,
    types.FETCH_APPS_FAILURE,
  ],
  callAPI: () =>
    secureCall(`/apps`)
      .then(json)
      .then(res => normalize(res, schemas.arrayOfApp)),
});
export const fetchApp = id => ({
  types: [
    types.FETCH_APPS_REQUEST,
    types.FETCH_APPS_SUCCESS,
    types.FETCH_APPS_FAILURE,
  ],
  callAPI: () =>
    secureCall(`/apps/${id}`)
      .then(json)
      .then(res => normalize(res, schemas.app)),
  shouldCallAPI: state => getApp(state, id) === null,
  payload: { id },
});

export const createApp = payload => ({
  types: [
    types.CREATE_APP_REQUEST,
    types.CREATE_APP_SUCCESS,
    types.CREATE_APP_FAILURE,
  ],
  callAPI: () =>
    secureCall(`/apps`, {
      method: 'POST',
      headers: JSON_HEADER,
      body: JSON.stringify(payload),
    })
      .then(json)
      .then(res => normalize(res, schemas.app)),
  payload,
});

export const updateApp = (id, payload) => ({
  types: [
    types.UPDATE_APP_REQUEST,
    types.UPDATE_APP_SUCCESS,
    types.UPDATE_APP_FAILURE,
  ],
  callAPI: () =>
    secureCall(`/apps/${id}`, {
      method: 'PATCH',
      headers: JSON_HEADER,
      body: JSON.stringify(payload),
    })
      .then(json)
      .then(res => normalize(res, schemas.app)),
  payload: {
    id,
    payload,
  },
});
