import { connect } from 'react-redux';
import App from './App';
import { getApp, fetchApp, getAppName } from '../../redux/modules/apps';

const mapStateToProps = (state, { id }) => ({
  app: getApp(state, id),
  name: getAppName(state, id),
});

const mapDispatchToProps = dispatch => ({
  fetchApp: id => dispatch(fetchApp(id)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(App);
