import { combineReducers } from 'redux';
import { makeById } from '../../../utils/standardReducers';
import types from './hooks.types';
import { secureCall, JSON_HEADER, json } from '../../../utils/api';
import schemas from '../schemas';
import { normalize } from 'normalizr';
import { createSelector } from 'reselect';
import get from 'lodash/get';

const _baseById = makeById('webhooks');
export const byId = (state = {}, action) => {
  let nextState = _baseById(state, action);
  if (action.type === types.UPDATE_HOOK_SUCCESS) {
    return {
      ...nextState,
      ...get(action, 'response.entities.webhooks', {}),
    };
  }
  return nextState;
};
export default combineReducers({
  byId,
});

/// SELECTORS
export const getById = state => state.hooks.byId;
/** (state, appId) */
export const getHooks = createSelector(
  getById,
  byId => Object.values(byId)
);
/** (state, hookId) */
export const getHook = createSelector(
  [getById, (_, id) => id],
  (byId, id) => get(byId, id, null)
);
/** (state, hookId) */
export const getHookUrl = createSelector(
  getHook,
  hook => get(hook, 'url')
);
export const getHookEvents = createSelector(
  getHook,
  hook => get(hook, 'events', [])
);
export const getHookHeaders = createSelector(
  getHook,
  hook => get(hook, 'headers')
);

// ACTIONS
export const fetchHooks = appId => ({
  types: [
    types.FETCH_HOOKS_REQUEST,
    types.FETCH_HOOKS_SUCCESS,
    types.FETCH_HOOKS_FAILURE,
  ],
  callAPI: () =>
    secureCall(`apps/${appId}/webhooks`)
      .then(json)
      .then(res => normalize(res, schemas.arrayOfHook)),
  payload: { appId },
});
export const fetchHook = id => ({
  types: [
    types.FETCH_HOOKS_REQUEST,
    types.FETCH_HOOKS_SUCCESS,
    types.FETCH_HOOKS_FAILURE,
  ],
  callAPI: () =>
    secureCall(`/webhooks/${id}`)
      .then(json)
      .then(res => normalize(res, schemas.hook)),
  shouldCallAPI: state => getHook(state, id) === null,
  payload: { id },
});

export const createHook = (appId, payload) => ({
  types: [
    types.CREATE_HOOK_REQUEST,
    types.CREATE_HOOK_SUCCESS,
    types.CREATE_HOOK_FAILURE,
  ],
  callAPI: () =>
    secureCall(`apps/${appId}/webhooks`, {
      method: 'POST',
      headers: JSON_HEADER,
      body: JSON.stringify(payload),
    })
      .then(json)
      .then(res => normalize(res, schemas.hook)),
  payload: {
    appId,
    payload,
  },
});

export const deleteHook = (appId, id) => ({
  types: [
    types.DELETE_HOOK_REQUEST,
    types.DELETE_HOOK_SUCCESS,
    types.DELETE_HOOK_FAILURE,
  ],
  callAPI: () => secureCall(`/webhooks/${id}`, { method: 'DELETE' }),
  payload: { appId, id },
});

export const updateHook = (id, payload) => ({
  types: [
    types.UPDATE_HOOK_REQUEST,
    types.UPDATE_HOOK_SUCCESS,
    types.UPDATE_HOOK_FAILURE,
  ],
  callAPI: () =>
    secureCall(`/webhooks/${id}`, {
      method: 'PUT',
      headers: JSON_HEADER,
      body: JSON.stringify(payload),
    })
      .then(json)
      .then(res => normalize(res, schemas.hook)),
  payload: {
    id,
    payload,
  },
});
