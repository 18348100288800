import React, { useEffect } from 'react';
import { withAuth } from '../../providers/AuthContext';
import styled from 'styled-components';
import { defineMessages, FormattedMessage } from 'react-intl';
import { Link, Match } from '@reach/router';
import { Drawer, DrawerHeader } from '../../components/material/Drawer';
import { DrawerTitle, DrawerContent } from '@rmwc/drawer';
import { List, ListItem } from '../../components/material/List';

const messages = defineMessages({
  basic: { id: 'app_sidebar_basic' },
  oauth: { id: 'app_sidebar_oauth' },
  hooks: { id: 'app_sidebar_hooks' },
});

const ContentWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  width: 59.36%;
  max-width: 650px;
  margin-left: 0px;
  padding-bottom: 150px;
`;

function SideBarLink({ to, message }) {
  return (
    <Link to={to} style={{ textDecoration: 'none' }}>
      <Match path={to}>
        {props => (
          <ListItem activated={props.match}>
            <FormattedMessage {...message} />
          </ListItem>
        )}
      </Match>
    </Link>
  );
}

function App({ id, fetchApp, children, name }) {
  useEffect(() => {
    fetchApp(id);
  }, []);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',

        position: 'relative',
      }}
    >
      <Drawer
        style={{ border: 'none', width: 228, marginRight: 10, zIndex: 2 }}
      >
        <DrawerHeader>
          <DrawerTitle>{name}</DrawerTitle>
        </DrawerHeader>
        <DrawerContent>
          <List>
            <SideBarLink to="basic" message={messages.basic} />
            <SideBarLink to="oauth" message={messages.oauth} />
            <SideBarLink to="webhooks" message={messages.hooks} />
          </List>
        </DrawerContent>
      </Drawer>

      <ContentWrapper>{children}</ContentWrapper>
    </div>
  );
}

export default withAuth(App);
