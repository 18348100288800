import '@material/drawer/dist/mdc.drawer.css';
import './Drawer.css';
import {
  Drawer as D,
  DrawerHeader as DH,
  DrawerTitle as DT,
  DrawerSubtitle as DS,
  DrawerContent as DC,
  DrawerAppContent as DA,
} from '@rmwc/drawer';

export const Drawer = D;
export const DrawerHeader = DH;
export const DrawerTitle = DT;
export const DrawerSubtitle = DS;
export const DrawerContent = DC;
export const DrawerAppContent = DA;
