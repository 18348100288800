import { connect } from 'react-redux';
import NewApp from './NewApp';
import { createApp } from '../../redux/modules/apps';

const mapStateToProps = state => ({
  apps: [],
});

const mapDispatchToProps = dispatch => ({
  createApp: payload => dispatch(createApp(payload)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NewApp);
