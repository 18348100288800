/* Copied from the Redux documentation:
 http://redux.js.org/docs/recipes/ReducingBoilerplate.html

 Added:
 - messages: an array of 3 items containing notification messages
             that should be sent on request, success, failure
 */

function callAPIMiddleware({ dispatch, getState }) {
  return next => action => {
    const { types, callAPI, shouldCallAPI = () => true, payload = {} } = action;

    if (!types) {
      // Normal action: pass it on
      return next(action);
    }

    if (
      !Array.isArray(types) ||
      types.length !== 3 ||
      !types.every(type => typeof type === 'string')
    ) {
      throw new Error('Expected an array of three string types.');
    }

    if (typeof callAPI !== 'function') {
      throw new Error('Expected callAPI to be a function.');
    }

    if (shouldCallAPI && !shouldCallAPI(getState())) {
      return;
    }

    const [requestType, successType, failureType] = types;

    dispatch(
      Object.assign({}, payload, {
        type: requestType,
      })
    );

    return callAPI().then(
      response => {
        return dispatch(
          Object.assign({}, payload, {
            response,
            type: successType,
          })
        );
      },
      error => {
        dispatch(
          Object.assign({}, payload, {
            error,
            type: failureType,
          })
        );
      }
    );
  };
}

export default callAPIMiddleware;
