import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Typography from '../../../../components/material/Typography';
import { defineMessages, FormattedMessage } from 'react-intl';
import { Section } from '../../../../components/Section';
import ScopeInput from './ScopeInput';
import CircularProgress from '../../../../components/material/CircularProgress';
import { Spacer } from '../../../../components/Spacer';
import Button from '../../../../components/material/Button';
import { scopeToText, JOIN_TOKEN } from './scopes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconButton } from '../../../../components/material/IconButton';

const messages = defineMessages({
  scopes: { id: 'app_oauth_scopes' },
  scopesSubtitle: { id: 'app_oauth_scopesSubtitle' },
});

const Wrapper = styled.form`
  display: flex;
  flex-direction: column;
`;

const ScopesWrapper = styled.div`
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
`;
const Scope = styled.div`
  padding: 15px 10px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

function ScopeSection({ id, scope, updateScope }) {
  const [eScope, setScope] = useState(scope);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setScope(scope);
  }, [scope]);

  const addScope = scope => {
    let next = scope;
    if (eScope) next += `,${eScope}`;
    setScope(next);
  };

  const saveScope = async () => {
    setLoading(true);
    await updateScope(id, eScope);
    setLoading(false);
  };

  const deleteScope = scopeId => {
    const scopes = eScope.split(JOIN_TOKEN).filter(x => x !== scopeId);
    const next = scopes.join(JOIN_TOKEN);
    setScope(next);
  };

  return (
    <Section>
      <Wrapper
        onSubmit={e => {
          e.preventDefault();
          saveScope();
        }}
      >
        <Typography use="headline5" style={{ marginBottom: 15 }}>
          <FormattedMessage {...messages.scopes} />
        </Typography>
        <Typography use="body2" style={{ marginBottom: 15 }} rank="primary">
          <FormattedMessage {...messages.scopesSubtitle} />
        </Typography>
        <ScopeInput addScope={addScope} currentScope={eScope} />
        <Spacer />
        <ScopesWrapper>
          {!eScope ? (
            <div
              style={{ display: 'flex', justifyContent: 'center', padding: 10 }}
            >
              <Typography use="body2" rank="secondary">
                You haven’t added any scope
              </Typography>
            </div>
          ) : null}
          {eScope
            .split(JOIN_TOKEN)
            .filter(x => x)
            .map(scopeId => (
              <Scope key={scopeId}>
                <div>
                  <div>
                    <Typography use="body1" rank="primary">
                      {scopeToText[scopeId]}
                    </Typography>
                  </div>
                  <div>
                    <Typography use="body2" rank="secondary">
                      {scopeId}
                    </Typography>
                  </div>
                </div>
                <div>
                  <IconButton
                    style={{ fontSize: 18, color: 'rgba(0,0,0,0.72)' }}
                    onClick={e => {
                      e.preventDefault();
                      deleteScope(scopeId);
                    }}
                    icon={<FontAwesomeIcon icon={['far', 'trash-alt']} />}
                  />
                </div>
              </Scope>
            ))}
        </ScopesWrapper>
        <Spacer />
        <Button
          label={loading ? 'Loading...' : 'Save scopes'}
          unelevated
          disabled={eScope === scope || loading}
          style={{ marginLeft: 'auto' }}
          icon={loading && <CircularProgress />}
        />
      </Wrapper>
    </Section>
  );
}

export default ScopeSection;
