import React, { useState, useEffect } from 'react';
import Modal from '../../../../components/Modal';
import Typography from '../../../../components/material/Typography';
import { CircularProgress } from '../../../../components/material/CircularProgress';
import styled from 'styled-components';
import Button from '../../../../components/material/Button';
import { Spacer } from '../../../../components/Spacer';
import { TextField } from '../../../../components/material/Texfield';
import uuid from 'uuid/v4';
import { defineMessages, FormattedMessage } from 'react-intl';
import isURL from 'validator/lib/isURL';
import { SimpleDialog } from '../../../../components/material/Dialog';
import { ThemeProvider } from '@rmwc/theme';
import { withTheme } from '@rmwc/base';
import Headers from './Headers';
import Events from './Events';

const messages = defineMessages({
  urlHelp: { id: 'hooks_urlHelp' },
  headersHelp: { id: 'hooks_headersHelp' },
});

const Wrapper = styled.form`
  display: flex;
  flex-direction: column;

  --mdc-theme-primary: ${x => x.theme.primary1};
`;

function NewHook({
  appId,
  hookId,

  isOpen,
  onClose,

  createHook,
  deleteHook,
  updateHook,

  startUrl = '',
  startHeaders = [],
  startEvents = [{ id: uuid(), entity: '', type: '' }],
}) {
  const [url, setUrl] = useState(startUrl);
  const [loading, setLoading] = useState(false);
  const [headers, setHeaders] = useState(startHeaders);
  const [events, setEvents] = useState(startEvents);

  const [confirmOpen, setConfirmOpen] = useState(false);

  useEffect(() => {
    setHeaders(startHeaders);
    setEvents(startEvents);
    setUrl(startUrl);
  }, [hookId]);

  const _deleteHook = () => {
    deleteHook(appId, hookId);
    onClose();
  };

  const _submit = async e => {
    e.preventDefault();

    setLoading(true);
    const payload = {
      url,
      headers,
      events,
    };
    if (hookId) {
      await updateHook(hookId, payload);
    } else {
      await createHook(appId, payload);
    }
    setLoading(false);
    onClose();
  };

  const _isReady = () => {
    const checkValid = () => {
      if (!url) return false;
      if (!isURL(url)) return false;
      for (let i = 0; i < events.length; ++i) {
        if (!events[i].type || !events[i].entity) return false;
      }
      for (let i = 0; i < headers.length; ++i) {
        if (!headers[i].header || !headers[i].value) return false;
      }
      return true;
    };
    const checkIsDifferent = () => {
      if (url !== startUrl) return true;
      if (events.length !== startEvents.length) return true;
      if (headers.length !== startHeaders.length) return true;

      const eventsMap = {};
      startEvents.forEach(e => (eventsMap[e.id] = e));
      for (let i = 0; i < events.length; ++i) {
        const e = eventsMap[events[i].id];
        if (!e || e.type !== events[i].type || e.entity !== events[i].entity)
          return true;
      }

      const headersMap = {};
      startHeaders.forEach(h => (headersMap[h.id] = h));
      for (let i = 0; i < headers.length; ++i) {
        const h = headersMap[headers[i].id];
        if (
          !h ||
          headers[i].header !== h.header ||
          headers[i].value !== h.value
        )
          return true;
      }
      return false;
    };

    const valid = checkValid();
    if (!valid) return false;

    if (!hookId) return valid;

    return valid && checkIsDifferent();
  };

  const renderHeader = () => (
    <div style={{ position: 'relative' }}>
      <Typography use="headline4">
        {hookId ? 'Edit Webhook' : 'New Webhook'}
      </Typography>
      {!hookId ? null : (
        <Button
          label={'Delete webhook'}
          outlined
          danger
          onClick={() => {
            setConfirmOpen(true);
          }}
          style={{
            position: 'absolute',
            top: 10,
            right: 10,
          }}
        />
      )}
    </div>
  );

  const renderUrlSection = () => (
    <>
      <TextField
        outlined
        required
        type="url"
        label={'Request URL'}
        value={url}
        onChange={e => setUrl(e.target.value)}
      />
      <Typography use="body2" rank="secondary">
        <FormattedMessage {...messages.urlHelp} />
      </Typography>
    </>
  );

  const renderDeleteConfirmation = () => (
    <ThemeProvider options={{ primary: '#4990E2' }}>
      <SimpleDialog
        title="Delete webhook"
        body="Are you sure that you want to remove this webhook?"
        acceptLabel="Yes, delete it"
        cancelLabel="No, keep it"
        open={confirmOpen}
        onClose={evt => {
          if (evt.detail.action === 'accept') {
            _deleteHook();
          }
          setConfirmOpen(false);
        }}
      />
    </ThemeProvider>
  );

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} style={{ maxWidth: 550 }}>
        {renderHeader()}
        <Spacer />
        <Spacer />
        <Wrapper autoComplete="on" onSubmit={_submit}>
          {renderUrlSection()}
          <Spacer />
          <Spacer />
          <Headers headers={headers} setHeaders={setHeaders} />
          <Spacer />
          <Spacer />
          <Events events={events} setEvents={setEvents} />
          <Spacer />
          <Button
            label={
              loading
                ? 'Loading...'
                : hookId
                ? 'Update webhook'
                : 'Save webhook'
            }
            unelevated
            disabled={loading || !_isReady()}
            style={{ marginLeft: 'auto' }}
            icon={loading && <CircularProgress />}
          />
        </Wrapper>
        {renderDeleteConfirmation()}
      </Modal>
    </>
  );
}

export default withTheme(NewHook);
