const pre = 'keenoa/apps/';

export default {
  CREATE_APP_REQUEST: `${pre}CREATE_APP_REQUEST`,
  CREATE_APP_SUCCESS: `${pre}CREATE_APP_SUCCESS`,
  CREATE_APP_FAILURE: `${pre}CREATE_APP_FAILURE`,

  FETCH_APPS_REQUEST: `${pre}FETCH_APPS_REQUEST`,
  FETCH_APPS_SUCCESS: `${pre}FETCH_APPS_SUCCESS`,
  FETCH_APPS_FAILURE: `${pre}FETCH_APPS_FAILURE`,

  FETCH_APP_REQUEST: `${pre}FETCH_APP_REQUEST`,
  FETCH_APP_SUCCESS: `${pre}FETCH_APP_SUCCESS`,
  FETCH_APP_FAILURE: `${pre}FETCH_APP_FAILURE`,

  UPDATE_APP_REQUEST: `${pre}UPDATE_APP_REQUEST`,
  UPDATE_APP_SUCCESS: `${pre}UPDATE_APP_SUCCESS`,
  UPDATE_APP_FAILURE: `${pre}UPDATE_APP_FAILURE`,
};
