import React, { useState, useEffect } from 'react';
import { TextField } from '../../components/material/Texfield';
import styled from 'styled-components';
import Button from '../../components/material/Button';
import Typography from '../../components/material/Typography';
import Link from '../../components/Link';
import { insecureFetch, JSON_HEADER, json } from '../../utils/api';
import { notify } from 'react-notify-toast';
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl';
import { navigate } from '@reach/router';
import Auth from './Auth';

const messages = defineMessages({
  title: { id: 'signup_title' },
  password: { id: 'login_password' },
  email: { id: 'login_email' },
  name: { id: 'signup_name' },
  company: { id: 'signup_company' },
  already: { id: 'signup_already' },
  submit: { id: 'signup_submit' },
  error: { id: 'login_error' },
});

const Form = styled.form`
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 425px;
  justify-content: space-around;
  padding: 30px;
  background-color: white;

  --mdc-theme-primary: ${x => x.theme.primary1};
`;

function SignUp({ intl, setToken }) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [emailRef, setEmailRef] = useState(null);
  const [company, setCompany] = useState('');
  const [name, setName] = useState('');

  useEffect(() => {
    if (!emailRef) return;
    setTimeout(() => emailRef.focus(), 100);
  }, [emailRef]);

  async function submit() {
    const res = await insecureFetch('/developers/register', {
      method: 'post',
      headers: JSON_HEADER,
      body: JSON.stringify({ email, password, name, company }),
    })
      .then(json)
      .catch(err => {
        setPassword('');
        notify.show(err.message, 'error');
        return null;
      });
    if (!res) return;
    notify.show('Signup successfull', 'success');
    setToken(res.token);
    navigate('/', true);
  }

  return (
    <Auth>
      <Form
        autoComplete="on"
        onSubmit={e => {
          e.preventDefault();
          submit();
        }}
      >
        <Typography use="headline4">
          <FormattedMessage {...messages.title} />
        </Typography>
        <TextField
          outlined
          required
          inputRef={x => setEmailRef(x)}
          type="email"
          label={intl.formatMessage(messages.email)}
          autoComplete="email"
          value={email}
          onChange={e => setEmail(e.target.value)}
        />
        <TextField
          outlined
          required
          label={intl.formatMessage(messages.password)}
          type="password"
          autoComplete="current-password"
          value={password}
          onChange={e => setPassword(e.target.value)}
        />
        <TextField
          outlined
          required
          type="text"
          label={intl.formatMessage(messages.name)}
          value={name}
          onChange={e => setName(e.target.value)}
        />
        <TextField
          outlined
          required
          type="text"
          label={intl.formatMessage(messages.company)}
          value={company}
          onChange={e => setCompany(e.target.value)}
        />
        <Link to="/signin">{intl.formatMessage(messages.already)}</Link>

        <Button
          label={intl.formatMessage(messages.submit)}
          unelevated
          disabled={!email || !password || !name || !company}
          style={{ width: 90, marginLeft: 'auto' }}
        />
      </Form>
    </Auth>
  );
}

export default injectIntl(SignUp);
