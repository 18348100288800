import React, { useState } from 'react';
import Modal from '../../components/Modal';
import Typography from '../../components/material/Typography';
import { TextField } from '../../components/material/Texfield';
import { CircularProgress } from '../../components/material/CircularProgress';
import styled from 'styled-components';
import Button from '../../components/material/Button';

const Wrapper = styled.form`
  display: flex;
  flex-direction: column;

  --mdc-theme-primary: ${x => x.theme.primary1};
`;

const Spacer = styled.div`
  height: 10px;
`;

function NewApp({ isOpen, onClose, createApp }) {
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [website, setWebsite] = useState('');
  const [privacy, setPrivacy] = useState('');
  const [terms, setTerms] = useState('');
  const [callbacks, setCallbacks] = useState('');

  const [loading, setLoading] = useState(false);

  async function submit() {
    setLoading(true);
    await createApp({
      name,
      description,
      website_url: website,
      privacy_url: privacy,
      terms_url: terms,
      callback_urls: callbacks.split(',').map(x => x.trim()),
    });
    setLoading(false);
    onClose();
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Typography use="headline4">New app</Typography>
      <Spacer />
      <Spacer />
      <Wrapper
        autoComplete="on"
        onSubmit={e => {
          e.preventDefault();
          submit();
        }}
        onKeyPress={e => {
          if (e.key === 'Enter') {
            e.preventDefault();
            submit();
          }
        }}
      >
        <TextField
          outlined
          required
          type="text"
          label={'Name'}
          value={name}
          onChange={e => setName(e.target.value)}
        />
        <Spacer />
        <TextField
          outlined
          required
          textarea
          rows="3"
          type="text"
          characterCount
          maxLength={300}
          label={'Description'}
          value={description}
          onChange={e => setDescription(e.target.value)}
        />
        <Spacer />
        <TextField
          outlined
          required
          type="url"
          label={'Website url'}
          value={website}
          onChange={e => setWebsite(e.target.value)}
        />
        <Spacer />
        <TextField
          outlined
          required
          type="url"
          label={'Privacy url'}
          value={privacy}
          onChange={e => setPrivacy(e.target.value)}
        />
        <Spacer />
        <TextField
          outlined
          required
          type="url"
          label={'Terms url'}
          value={terms}
          onChange={e => setTerms(e.target.value)}
        />
        <Spacer />
        <TextField
          outlined
          required
          textarea
          rows="3"
          type="text"
          label={'Callback urls'}
          helpText={{
            persistent: true,
            validationMsg: true,
            children: 'Make sure that the urls are comma separated.',
          }}
          value={callbacks}
          onChange={e => setCallbacks(e.target.value)}
        />
        <Spacer />
        <Button
          label={loading ? 'Loading...' : 'Create app'}
          unelevated
          disabled={
            !name || !description || !website || !terms || !privacy || loading
          }
          style={{ marginLeft: 'auto' }}
          icon={loading && <CircularProgress />}
        />
      </Wrapper>
    </Modal>
  );
}

export default NewApp;
