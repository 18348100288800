import React from 'react';
import Typography from '../../../../components/material/Typography';
import styled from 'styled-components';
import Button from '../../../../components/material/Button';
import { Spacer } from '../../../../components/Spacer';
import { TextField } from '../../../../components/material/Texfield';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconButton } from '../../../../components/material/IconButton';
import { defineMessages, FormattedMessage } from 'react-intl';
import uuid from 'uuid/v4';

const messages = defineMessages({
  headersHelp: { id: 'hooks_headersHelp' },
});

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
`;

function Headers({ headers, setHeaders }) {
  const _deleteHeader = id => {
    setHeaders(headers.filter(h => h.id !== id));
  };
  const _addHeader = e => {
    e.preventDefault();

    const newH = { id: uuid(), header: '', value: '' };
    setHeaders([...headers, newH]);
  };
  const _updateValue = (id, value) => {
    setHeaders(
      headers.map(h => {
        if (h.id !== id) return h;
        return { ...h, value };
      })
    );
  };
  const _updateHeader = (id, header) => {
    setHeaders(
      headers.map(h => {
        if (h.id !== id) return h;
        return { ...h, header };
      })
    );
  };

  const renderHeader = ({ id, header, value }) => {
    return (
      <HeaderWrapper key={id}>
        <TextField
          autoFocus
          outlined
          required
          style={{ flex: 1, marginRight: 5 }}
          type="text"
          label={'Header'}
          value={header}
          onChange={e => _updateHeader(id, e.target.value)}
        />
        <TextField
          outlined
          required
          style={{ flex: 2 }}
          type="text"
          label={'Value'}
          value={value}
          onChange={e => _updateValue(id, e.target.value)}
        />
        <div>
          <IconButton
            style={{ fontSize: 18, color: 'rgba(0,0,0,0.72)' }}
            onClick={e => {
              e.preventDefault();
              _deleteHeader(id);
            }}
            icon={<FontAwesomeIcon icon={['far', 'trash-alt']} />}
          />
        </div>
      </HeaderWrapper>
    );
  };

  return (
    <>
      <Typography use="subtitle2">Custom headers</Typography>
      <Typography use="body2" rank="secondary">
        <FormattedMessage {...messages.headersHelp} />
      </Typography>
      <div>
        <Spacer />
        {headers.map(renderHeader)}
        <Spacer />
        <Button label={'+ Add header'} onClick={_addHeader} />
      </div>
    </>
  );
}

export default Headers;
